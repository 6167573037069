import React from "react"
import ContentWithImage from "../../components/contentWithImage"
import Layout from "../../components/layout"
import { interIITs } from "../../data/co-curricular"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import { getWords } from "../../helper-functions"
function InterIit(props) {
  return (
    <div>
      <Layout>
        <SEO
          title="Inter IIT"
          description={getWords(interIITs[0].content, 60)}
          image={require("../../images/sports and interiit/Sports.png")}
        />
        <h2 className="dark-color-head">Inter IIT</h2>
        {interIITs.map(interIIT => (
          <div>
            <ContentWithImage
              {...interIIT}
              fluid={
                props.data.allFile.edges.find(
                  edge => edge.node.name === interIIT.title
                ).node.childImageSharp.fluid
              }
            />
          </div>
        ))}
      </Layout>
    </div>
  )
}

export default InterIit

export const query = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "sports and interiit" } }) {
      totalCount
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
  }
`
